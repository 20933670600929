import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { fetchEvaluationScores } from '../../redux/actions/evaluationActions';
import Loader from '../common/Loader';
import ScoreBar from './ScoreBar';
import ReportScoreRow from './ReportScoreRow';
// import {
//   EVALUATIONS_REPORTS_MODULAR_ROUTE,
//   EVALUATIONS_REPORTS_MINIMALISTIC_ROUTE,
//   EVALUATIONS_REPORTS_SCALABLE_ROUTE,
//   EVALUATIONS_REPORTS_SEMANTIC_ROUTE,
//   EVALUATIONS_REPORTS_STYLISTICALLY_CONSISTENT_ROUTE,
// } from '../../core/constants';

const getScoreBarScale = maxScore => {
  const step = maxScore / 5;

  return [
    { title: 'VERY POOR', score: `1 -  ${step}` },
    { title: 'POOR', score: `${step} - ${2 * step}` },
    { title: 'FAIR', score: `${2 * step} - ${3 * step}` },
    { title: 'GOOD', score: `${3 * step} - ${4 * step}` },
    { title: 'VERY GOOD', score: `${4 * step} - ${maxScore}` },
  ];
};

// const reportLinks = {
//   Minimalistic: EVALUATIONS_REPORTS_MINIMALISTIC_ROUTE,
//   Semantic: EVALUATIONS_REPORTS_SEMANTIC_ROUTE,
//   Scalable: EVALUATIONS_REPORTS_SCALABLE_ROUTE,
//   Modular: EVALUATIONS_REPORTS_MODULAR_ROUTE,
//   'Stylistically Consistent': EVALUATIONS_REPORTS_STYLISTICALLY_CONSISTENT_ROUTE,
// };

function EvaluationReports({ taskId }) {
  const dispatch = useDispatch();
  const { scores, loading } = useSelector(state => state.evaluation);
  const overallScore = scores.reduce((acc, v) => acc + v.score, 0);

  useEffect(() => {
    dispatch(fetchEvaluationScores(taskId));
  }, [dispatch, taskId]);

  // const getButtonLink = reportName => reportLinks[reportName];

  if (loading) {
    return <Loader />;
  }

  const maxScore = scores.reduce((acc, v) => acc + v.max_score, 0) || 50;
  const scoreBarScale = getScoreBarScale(maxScore);

  return (
    <Grid container justifyContent="center">
      <Grid className="py-4" item xs={10}>
        <ScoreBar title="Your Overall Score" score={overallScore} maxScore={maxScore} scale={scoreBarScale} />
      </Grid>

      <Grid item xs={10} lg={7}>
        {scores.map(report => (
          <ReportScoreRow
            key={report.name}
            reportName={report.name}
            maxScore={report.max_score}
            subScores={report.sub_scores}
            score={report.score}
            // btnLink={getButtonLink(report.name)}
          />
        ))}
      </Grid>
    </Grid>
  );
}

EvaluationReports.propTypes = {
  taskId: PropTypes.string.isRequired,
};

export default EvaluationReports;
