import React from 'react';
import PropTypes from 'prop-types';
// import { useHistory } from 'react-router-dom';
import {
  // Button,
  Grid,
  LinearProgress,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

const styledBy = property => props => props[property];

const ReportScoreBar = withStyles(theme => ({
  root: {
    height: 25,
    borderRadius: 8,
    backgroundColor: theme.palette.grey[300],
  },
  bar: {
    borderRadius: 8,
    background: styledBy('barColor'),
  },
}))(({ barColor, ...other }) => (
  <LinearProgress variant="determinate" {...other} />
));

const useStyles = makeStyles(() => ({
  reportName: {
    fontSize: 19,
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      fontSize: 20,
      marginLeft: 5,
      cursor: 'pointer',
    },
  },
  rowScoreText: {
    fontWeight: 'bold',
    color: '#8898aa',
  },
}));

const gradientColors = ['#d23333', '#d4532e', '#d77429', '#d99425', '#dbb420',
  '#ddd41b', '#bec625', '#9fb92e', '#7fab38', '#609d41', '#418f4a',
]; // the same as in gradient

function ReportScoreRow(props) {
  const {
    score, maxScore, subScores,
  } = props;
  const classes = useStyles();
  // const history = useHistory();

  const scoreBarValue = (score * 100) / maxScore;
  const colorIndex = Math.round((score / maxScore) * 10) - 1;
  const barColor = gradientColors[colorIndex];

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <Typography className={classes.reportName} variant="subtitle1">
              <span>{props.reportName}</span>
              <HelpOutlineOutlinedIcon />
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.rowScoreText} variant="h6" align="right">
              {score}
              /
              {maxScore}
            </Typography>
          </Grid>
          <Grid className="my-1" item xs={12}>
            <ReportScoreBar value={scoreBarValue} barColor={barColor} />
          </Grid>
        </Grid>
      </Grid>

      {subScores.length > 0
        && (
        <>
          <Grid item xs={1} />
          <Grid item xs={11}>
            {subScores.map(report => (
              <ReportScoreRow
                key={report.name}
                reportName={report.name}
                maxScore={report.max_score}
                score={report.score}
                subScores={report.sub_categories}
              />
            ))}
          </Grid>
        </>
        )}

      {/* <Grid className="d-flex align-items-center" item xs={2}>
        <Button variant="outlined" size="large" onClick={() => history.push(props.btnLink)}>
          More info
        </Button>
      </Grid> */}
    </Grid>
  );
}

ReportScoreRow.defaultProps = {
  maxScore: 10,
  subScores: [],
  // btnLink: '#',
};

ReportScoreRow.propTypes = {
  // btnLink: PropTypes.string,
  maxScore: PropTypes.number,
  subScores: PropTypes.array,
  reportName: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired, // must be less than `maxScore`
};

export default ReportScoreRow;
